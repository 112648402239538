<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
    [fullScreen]="true"></ngx-spinner>
<div id="category">
    <div class="container mt-3">
        <div class="row" style="font-size:0.8rem">
            <div *ngFor="let item of pages?.data ">
                <div *ngIf="item.attributes.post == true" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <a class="link" routerLink="/page/{{item.id}}" style="padding:0.5rem !important;color:#04375e">
                        <h4 style="font-size:1.2rem">{{item.attributes.title}}</h4>
                        <div style="color:#04375e;font-size:0.8rem" translate>posted <span>{{item.attributes.published}}</span></div>
                        <hr>
                    </a>
                </div>
            </div>
        </div>
        <div class="row justify-content-end">
            <p-paginator [first]="first" [rows]="1" #paginator [totalRecords]="numberOfPages"
                (onPageChange)="paginate($event)">
            </p-paginator>
        </div>
    </div>
</div>
