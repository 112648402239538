import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  constructor(private _Activatedroute: ActivatedRoute, private api: ApiService, private sanitizer: DomSanitizer,) { }
  id: any
  article: any
  apiurl = environment.apiUrlPublic
  body: any
  cover: any

  async ngOnInit() {

    this._Activatedroute.params.subscribe(async params => {
      // Defaults to 0 if no query param provided.
      this.id = params['id'] || 0;

      let page: any = await this.api.getPage(this.id).toPromise()

      this.article = page.data
      var date = new Date(this.article.attributes.published);
      var n = date.toLocaleDateString();
      var time = date.toLocaleTimeString();
      this.article.attributes.published = n + ' ' + time
      if (this.article.attributes.body) {
        this.body = this.sanitizer.bypassSecurityTrustHtml(this.article.attributes.body);
      } else {
        this.body = ''
      }
      if (this.article.attributes.cover.data) {
        this.cover = `${this.apiurl}${this.article.attributes.cover.data.attributes.url}`
      }


      if (this.article.attributes.files.data) {
        this.article.attributes.files.data.forEach((element: any) => {
          element.attributes.url = `${this.apiurl}${element.attributes.url}`
        });
      }

      if (this.article.attributes.photos.data) {
        this.article.attributes.photos.data.forEach((element: any) => {
          element.attributes.url = `${this.apiurl}${element.attributes.url}`
        });
      }
    });


  }

}
