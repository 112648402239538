<div id="contact" class="container bootstrap snippets bootdeys" style="margin-top:2rem">
<div class="row text-center">
    <div class="col-sm-4">
        <div class="contact-detail-box">
            <i class="pi pi-phone"></i>
            <h4 style="margin-top:1rem" translate>phone</h4>
            <p>2310-241668</p>
            <p>FAX: 2310-232667</p>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="contact-detail-box">
            <i class="pi pi-map-marker"></i>
            <h4 style="margin-top:1rem" translate>location</h4>
            <p translate>aristotelous <span> 27 – </span></p>
            <p >546.24 <span translate>thessaloniki</span></p>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="contact-detail-box">
            <i class="pi pi-envelope"></i>
            <h4 style="margin-top:1rem">EMAIL</h4>
            <a class="link" href='mailto:info@veth.gov.gr' style="color:#04375e">info@veth.gov.gr</a>
        </div>
    </div>
</div>
<div class="row" style="padding: 1rem;">
    <div class="col-sm-6">
        <div class="contact-map">
            <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.7693598203564!2d22.940237815635026!3d40.63496810026102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a839a837efed05%3A0xbb31601d2a2bcf63!2sThessaloniki%20Chamber%20of%20Handicrafts!5e0!3m2!1sen!2sgr!4v1652427407444!5m2!1sen!2sgr'
            frameborder='0' scrolling='no' marginheight='0' marginwidth='0' style='width: 100%; height: 580px;'>
                
            </iframe>
        </div>
    </div>
    <div class="col-sm-6">
        <form id="ajax-form form-main" role='form' name='ajax-form' #form="ngForm">
            <div class="form-group">
                <label for="name" translate>name-lastname<span style="color:red; margin-left:3px">*</span></label>
                <input id="name" class="form-control" name="name" type="text" ngModel required>
            </div> 
            <div class="form-group">
                <label for="phone" translate>phone<span style="color:red; margin-left:3px">*</span></label>
                <input id="phone" class="form-control" name="phone" type="text" ngModel required>
            </div> 
            <div class="form-group">
                <label for="email" translate>Email<span style="color:red; margin-left:3px">*</span></label>
                <input id="email" class="form-control" name="email" type="text" ngModel required>
            </div> 
            <div class="form-group">
                <label for="message" translate>message<span style="color:red; margin-left:3px">*</span></label>
                <textarea id="message" class="form-control" name="message" rows='5' cols='30' style="height: 10rem;" ngModel></textarea>
            </div> 
            <div class="form-group" style="margin-top: 1rem;">
                <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel"
                [(ngModel)]="token" required
                [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
            </div>
            <div class="form-group" style="margin-top: 1rem;">
               <button id="send" class="btn btn-primary btn-shadow btn-rounded w-md" (click)="send(form)" translate>send</button>
            </div>
        </form>
    </div>
</div>
</div>