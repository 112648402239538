import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.css']
})
export class PollComponent implements OnInit {

  constructor(private _Activatedroute: ActivatedRoute, private api: ApiService, private sanitizer: DomSanitizer,private snackBar: MatSnackBar) { }
  id: any
  poll: any

  async ngOnInit() {

    this._Activatedroute.params.subscribe(async params => {
      // Defaults to 0 if no query param provided.
      this.id = params['id'] || 0;
      let poll: any = await this.api.getPoll(this.id).toPromise()
      this.poll = poll.data
    })
  }

  public async send(form: NgForm) {
    console.log(form.value)
    let answer: any = await this.api.getAnswer(form.value.pollradio).toPromise()
  
    let data = { data: { votes: answer.data.attributes.votes + 1 } }
 
    let put = await this.api.putPoll(answer.data.id, data).toPromise()
    console.log(put)
    if(put){
      this.snackBar.open(
        "Η ψήφος σας καταγράφηκε επιτυχώς!", 
        "", 
        { 
          duration: 5000, 
          panelClass: ["snackBar", "snackBarSuccess"]
        }
      )
    }
  }
}
