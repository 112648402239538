<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
    [fullScreen]="true"></ngx-spinner>
<div id="category">
    <div class="container mt-3">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngFor="let item of subs">
                <a  style="padding:0.5rem !important;color:#04375e;cursor: pointer;" (click)="renderContent(item)">
                    <h4 style="font-size:1.2rem">{{item.attributes.name}}</h4>
                    <p style="font-weight:normal"> {{item.attributes.description}}</p>
                    <hr>
                </a>
            </div>
        </div>
    </div>
</div>