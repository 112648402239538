import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  apiUrl = environment.apiUrlPublic;
  responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1,
    },
  ];
  images: any;
  date = new Date().getFullYear();
  links: any = [];
  headerLinks: any[] = [];
  youtubeVideos: any[] = [];

  async ngOnInit() {
    let language: string =
      this.translate.currentLang === undefined
        ? 'el'
        : this.translate.currentLang;
    let links: any = await this.api.getLinks(language).toPromise();
    this.links = links.data;

    await this.fetchMenus(language);
    let banners: any = await this.api.getBanners().toPromise();
    this.images = banners.data;

    for (let index = 0; index < this.images.length; index++) {
      const element = this.images[index];
      element.attributes.banner.data.url =
        this.apiUrl + element.attributes.banner.data.attributes.url;
    }
    this.images.sort((a, b) => {
      if (a.attributes.rank > b.attributes.rank) {
        return 1;
      } else if (b.attributes.rank > a.attributes.rank) {
        return -1;
      }

      return 0;
    });

    const youtubeVideos: any = await this.api.getYoutubeVideos().toPromise();
    this.youtubeVideos = youtubeVideos.data;
    this.youtubeVideos = this.youtubeVideos
      .map((video) => {
        video.attributes.url = this.sanitizer.bypassSecurityTrustResourceUrl(
          video.attributes.url
        );
        return video;
      })
      .sort((a, b) => {
        if (a.attributes.rank > b.attributes.rank) {
          return 1;
        } else if (b.attributes.rank > a.attributes.rank) {
          return -1;
        }

        return 0;
      });

    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      if (event.lang == 'en') {
        await this.fetchMenus('en');
      }

      if (event.lang == 'el') {
        await this.fetchMenus('el');
      }
    });
  }

  //the function used to fetch the menu items. Is used at the ngOnInit, and at the onLangChange event.
  async fetchMenus(language: string) {
    this.headerLinks = [];
    let menus: any = await this.api.getHeaderLinks(language).toPromise();
    menus.data.forEach((element) => {
      if (!element.attributes.parent.data) {
        this.headerLinks = [...this.headerLinks, element];
      }
    });

    this.headerLinks = this.headerLinks.sort((a, b) =>
      a.attributes.rank > b.attributes.rank
        ? 1
        : b.attributes.rank > a.attributes.rank
        ? -1
        : 0
    );
  }
}
