import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  constructor(private api: ApiService, private route: ActivatedRoute, private translate: TranslateService,private spinner: NgxSpinnerService,) { }
  results: any[] = []; //the ids of all strapi-pages that contain at least one word of the query
  pages: any[] = []; //the strapi-pages themselves
  currentPages: any[] = [] //the strapi-pages that currently been displayed with paginator
  apiUrl: string = environment.apiUrlPublic;
  query = ''

  //paginator variables
  dataPerPage: number = 9; //for each page, the number of data (in our case data=pages from strapi) to be displayed is 10
  totalRecords: number; //the number of total strapi-pages that contain in title or body one or more words that user searched
  numberOfPages: number; //the number of paginator pages. For example, if we have 20 data (strapi-pages) and we want to display 10 data
  //per page, then the number of pages are 20 / 10 = 2
  queryText: string; //the text user wants to search
  language = 'el'

  ngOnInit() {
    this.route.queryParams.subscribe(async (queryParams: Params) => {
      this.spinner.show()
      //clear the previous data
      this.pages = [];
      this.results = [];
      this.currentPages = [];
      this.language = this.translate.currentLang === undefined ? 'el' : this.translate.currentLang;


      this.queryText = queryParams.queryText.split(' ');


      for (let index = 0; index < this.queryText.length; index++) {
        let key = this.queryText[index];
        key = this.filterWord(key);
        this.query = this.query + `filters[$or][0][title][$contains]=${key}&filters[$or][1][body][$contains]=${key}`
      }

      let response: any = await this.api.search(this.language, this.query, 1, this.dataPerPage).toPromise();
      this.results = response.data
      this.totalRecords = response.meta.pagination.total
      // console.log(this.totalRecords)
      this.numberOfPages = Math.ceil(this.totalRecords / this.dataPerPage);


      let counter = 0;
      this.results.forEach(element => {
        element = this.editPage(element);
        this.pages.push(element);
        if (counter < this.dataPerPage) {
          this.currentPages.push(element);
        }
        counter += 1;
      });
      this.spinner.hide()
    })
  }

   //makes word lowercase and replaces greek toned characters with their corresponding non-toned
   filterWord(word: string): string {
    word = word.toLowerCase();
    word = this.replaceTonedGreekCharacters(word);
    return word;
  }

   //Replaces the greek letter with tonos that exist in the given word, with the same letters without tonos
   replaceTonedGreekCharacters(word: string): string {
    const tonedCharacters: string[] = ['ά', 'έ', 'ή', 'ί', 'ύ', 'ό', 'ώ'];
    const nonTonedCharacters: string[] = ['α', 'ε', 'η', 'ι', 'υ', 'ο', 'ω'];

    let index: number = 0;
    let newWord: string = "";
    for (let i = 0; i < word.length; i++) {
      index = tonedCharacters.findIndex((letter: string) => letter === word[i]);
      if (index !== -1) { //you found greek letter with tonos
        newWord += nonTonedCharacters[index];
        continue;
      }
      newWord += word[i];
    }
    return newWord;
  }

   //gets a page as parameter, and alters the url of its image. If url exists, it append public url to this url. Otherwise,
  //the function sets a default random asset from assets folder
  editPage(page: any) {
    let x: number;
    var date2 = new Date(page.attributes.published);
    var n = date2.toLocaleDateString('el-GR', { timeZone: 'Europe/Athens' })
    page.attributes.published = n

    if (page.attributes.cover.data) {
      page.attributes.cover.data.attributes.url = this.apiUrl + page.attributes.cover.data.attributes.url;
    } else {
      // x = this.randomIntFromInterval(1, 4);
      page.cover = `/assets/logo.png`;
    }
    return page;
  }

  async paginate(event: any) {
    //goes to the next page, and fetches its content
    // this.currentPages = this.getCurrentPages(event.page);
    // console.log(event.page + 1)
    this.spinner.show()
    this.currentPages = []
    for (let index = 0; index < this.queryText.length; index++) {
      let key = this.queryText[index];
      key = this.filterWord(key);
      this.query = this.query + `filters[$or][0][title][$contains]=${key}&filters[$or][1][body][$contains]=${key}`
    }

    let response: any = await this.api.search(this.language, this.query, event.page + 1, this.dataPerPage).toPromise();
    this.results = response.data
    this.totalRecords = response.meta.pagination.total
    // console.log(this.totalRecords)
    this.numberOfPages = Math.ceil(this.totalRecords / this.dataPerPage);

    let counter = 0;
    this.results.forEach(element => {
      element = this.editPage(element);
      this.pages.push(element);
      if (counter < this.dataPerPage) {
        this.currentPages.push(element);
      }
      counter += 1;
    });
    this.spinner.hide()
  }

}
