import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomepageComponent } from './homepage/homepage.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorInterceptor } from './error.interceptor';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './components/footer/footer.component';
import { PageComponent } from './page/page.component';
import { CategoryComponent } from './category/category.component';
import { MenuClickSideComponent } from './menu-click-side/menu-click-side.component';
import { MenuClickComponent } from './menu-click/menu-click.component';
import { ContactComponent } from './contact/contact.component';
import { PaginatorModule } from 'primeng/paginator';
import { UsefulLinksComponent } from './useful-links/useful-links.component';
import { FaqsComponent } from './faqs/faqs.component';
import { CalendarComponent } from './calendar/calendar.component';
import { AccordionModule } from 'primeng/accordion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha'
import { environment } from 'src/environments/environment';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PollsComponent } from './polls/polls.component';
import { PollComponent } from './poll/poll.component';
import { CalendarModule } from 'primeng/calendar';
import { EventCalendarComponent } from './event-calendar/event-calendar.component';
import { SearchComponent } from './search/search.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { OpinionFormComponent } from './opinion-form/opinion-form.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

//the configuration of the cookie
const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
    "domain": environment.cookieDomain
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#1976d2",
      "text": "#ffffff"
    },
    "button": {
      "background": "#f1d600",
      "text": "#000000"
    }
  },
  "type": "opt-out",
  "content": {
    "message": "Αυτός ο ιστότοπος χρησιμοποιεί cookies για να εξασφαλίσει ότι θα έχετε την καλύτερη δυνατή εμπειρία.",
    "dismiss": "Το καταλαβα!",
    "allow": "Αποδοχή cookies",
    "deny": "Άρνηση",
    "link": "Μάθετε περισσότερα",
    "policy": "Πολιτική Cookies",
    "href": "https://ec.europa.eu/info/cookies_el",
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    HomepageComponent,
    FooterComponent,
    PageComponent,
    CategoryComponent,
    MenuClickSideComponent,
    MenuClickComponent,
    ContactComponent,
    UsefulLinksComponent,
    FaqsComponent,
    CalendarComponent,
    PollsComponent,
    PollComponent,
    EventCalendarComponent,
    SearchComponent,
    OpinionFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CarouselModule,
    PaginatorModule,
    TranslateModule,
    RecaptchaModule,
    MatSnackBarModule,
    NgxSpinnerModule,
    CalendarModule,
    RecaptchaFormsModule,
    AccordionModule,
    BrowserAnimationsModule,
    NgbCarouselModule,
    GalleriaModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }, 
      defaultLanguage: 'el'
    }),
    NgbModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.sitekey,
      } as RecaptchaSettings,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
      deps: [Injector],
    },
  ],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
