<div class="container mt-5">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="color:#04375e">
            <div *ngFor="let item of polls">
                <li class="list-group-item">
                    <a class="link" routerLink="/poll/{{item.id}}" style='color:#04375e'>{{item.attributes.question}}</a>
                </li>
            </div>
        </div>
    </div>
</div>