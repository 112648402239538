<div class="container mt-5">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="color:#04375e">
            <div *ngFor="let item of links">
                <li class="list-group-item">
                    <a class="link" routerLink={{item.attributes.url}} style='color:#04375e'>{{item.attributes.name}}</a>
                </li>
            </div>
        </div>
    </div>
</div>