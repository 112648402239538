<div class="container mt-1">
    <div class="row" style="padding: 1rem;">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <header class="mb-4">
                <h3 class="fw-bolder mb-1">{{poll.attributes.question}}</h3>
            </header>
            <form id="ajax-form" class="form-main" role='form' name='ajax-form' #form="ngForm">
                <div class="form-check" *ngFor="let item of poll.attributes.answers.data">
                    <div class="form-check">
                        <input class="form-check-input" type='radio' name='pollradio' value={{item.id}} ngModel> {{item.attributes.answer}}
                    </div>
                   
                </div>
                <div class="form-check">
                    <button class="btn btn-primary btn-shadow btn-rounded w-md" style="margin-top: 1rem;margin-bottom:2rem" (click)="send(form)" translate>send</button>
                </div>
            </form>
        </div>
    </div>
</div>