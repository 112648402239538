import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {

  constructor(private api: ApiService, private translate: TranslateService) { }
  faqs: any[] = []

  async ngOnInit(): Promise<void> {
    let faqs: any = await this.api.getFaqs('el').toPromise()
    this.faqs = faqs.data

    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      faqs = await this.api.getFaqs(event.lang).toPromise()
      this.faqs = faqs.data
    })
  }

}
