<div class="container mt-1">
    <div class="row" style="padding: 1rem;">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h4 class="link" translate>calendar</h4>
            <div style='display:flex;flex-direction:column;justify-content:center;align-items:center;color:#04375e'>
                <p-calendar [(ngModel)]="date" [inline]="true" [showOtherMonths]='false' class="max-w-full"
                (onMonthChange)="onMonthChange($event)" (onSelect)="onSelect(date)">
                <ng-template pTemplate="date" let-date>
                    <span [ngClass]="checkDateForHoliday(date) ==true ? 'eventDay' : 'normalDay'">{{date.day}}</span>
                </ng-template>
            </p-calendar>
            <div *ngFor="let item of currentEvents">
                <a routerLink="/page/{{item.id}}" class="link2">{{item.attributes.title}}</a>
            </div>
            </div>
            
        </div>
    </div>
</div>