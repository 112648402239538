<div class="container mt-1">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="color:#04375e">
            <h2 style="padding:1rem" translate>faqs</h2>

            <p-accordion [activeIndex]="0">
                <div *ngFor="let item of faqs">
                    <p-accordionTab header="{{item.attributes.question}}">
                        <p>
                            {{item.attributes.answer}}
                        </p>
                    </p-accordionTab>
                </div>
            </p-accordion>
        </div>
    </div>
</div>