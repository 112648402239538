import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-opinion-form',
  templateUrl: './opinion-form.component.html',
  styleUrls: ['./opinion-form.component.css']
})
export class OpinionFormComponent implements OnInit {

  constructor(private api: ApiService, private snackBar: MatSnackBar) {
    this.token = undefined;
  }
  token: string | undefined;

  ngOnInit(): void {
  }

  public async send(form: NgForm) {

    if (form.valid) {
      //create the object that will be sent to the api
    type objType = {
      to: string, //email receiver
      from: string, //email sender
      subject: string, //the subject of the email
      text: string, //the message
      html: string //the html template of the email
    }

    //this will be the message that will be sent with the email
    const htmlTemplate: string = 
    `
      <head>Φόρμα Άποψης - Θέσης Βιοτεχνικού Επιμελητηρίου Θεσσαλονίκης</head>
      <body>
        <h2>Στοιχεία επικοινωνίας αποστολέα:</h2>
        <p>Ονοματεπώνυμο: ${form.value.name}</p>
        <p>Τηλέφωνο: ${form.value.phone}</p>
        <p>Email: ${form.value.email}</p>
        <div>
          <p>${form.value.message}<p>
        </div>
      </body>
    `

    //defining the object that will be sent to the api
    const myObject: objType = {
      to: "e-news@veth.gov.gr",
      from: "no-reply@veth.gov.gr", 
      subject: "Φόρμα Άποψης - Θέσης ΒΕΘ",
      text: "Φόρμα Άποψης - Θέσης ΒΕΘ",
      html: htmlTemplate,
    } 
    console.log(htmlTemplate)
    const response: any = await this.api.postEmail(myObject).toPromise();

    //snackBar opening for successfull email sending
    this.snackBar.open(
      "Το email στάλθηκε επιτυχώς!", 
      "Το κατάλαβα!", 
      { 
        duration: 5000, 
        panelClass: ["snackBar", "snackBarSuccess"]
      }
    )

  } else {
    //snackBar opening for failed email sending
    this.snackBar.open(
      "Το email δεν στάλθηκε! Βεβαιωθείτε ότι έχετε αποδεχτεί το captcha.", 
      "Το κατάλαβα!", 
      {
        duration: 5000,
        panelClass: ["snackBar", "snackBarFailure"]
      }
    )
  }
  }
}
