import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-menu-click',
  templateUrl: './menu-click.component.html',
  styleUrls: ['./menu-click.component.css']
})
export class MenuClickComponent implements OnInit {

  constructor(private api: ApiService, private translate: TranslateService, private _Activatedroute: ActivatedRoute, private router: Router,private spinner: NgxSpinnerService,) { }
  subs: any[] = []
  id: any
  language = 'el'
  apiurl = environment.apiUrlPublic

  async ngOnInit() {

    this._Activatedroute.params.subscribe(async params => {
      // Defaults to 0 if no query param provided.
      this.id = params['id'] || 0;

      if (this.translate.currentLang == 'en') {
        this.language = 'en'
      } else {
        this.language = 'el'
      }
      this.spinner.show()
      await this.fetchMenus(this.language);
      this.spinner.hide()

      this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
        this.spinner.show()
        if (event.lang == 'en') {
          await this.fetchMenus('en');
        }

        if (event.lang == 'el') {
          await this.fetchMenus('el');
        }
        this.spinner.hide()
      })
    })

  }

  //the function used to fetch the menu items. Is used at the ngOnInit, and at the onLangChange event.
  async fetchMenus(language: string) {
    this.subs = []
    let menus: any = await this.api.getHeaderLinks(language).toPromise();

    menus.data.forEach(element => {

      element.children = []
      menus.data.forEach(obj => {
          if (obj.attributes.parent.data && obj.attributes.parent.data.id == element.id) {
              element.children = [...element.children, obj]
          }
          
      });
      if(element.attributes.file.data){
        element.attributes.file.data.attributes.url = `${this.apiurl}${element.attributes.file.data.attributes.url}`
      }
      element.children.sort((a, b) => (a.attributes.rank > b.attributes.rank) ? 1 : ((b.attributes.rank > a.attributes.rank) ? -1 : 0))

  });

    menus.data.forEach(element2 => {
      if (element2.attributes.parent.data && element2.attributes.parent.data.id == this.id) {
        if(element2.attributes.category.data || element2.attributes.page.data || element2.attributes.url !='' || element2.attributes.parent.data){
          this.subs = [...this.subs, element2]
        }
        
      }
    });
    this.subs.sort((a, b) => (a.attributes.rank < b.attributes.rank) ? 1 : ((b.attributes.rank < a.attributes.rank) ? -1 : 0))
  }

  async renderContent(item) {
    if(item.children.length > 0){
      this.router.navigate([`/menu-click/${item.id}`]);
    }
    else if (item.attributes.category.data) {
      this.router.navigate([`/category/${item.attributes.category.data.id}/1`]);
    } else if (item.attributes.page.data) {
      this.router.navigate([`/page/${item.attributes.page.data.id}`]);
    } else if (item.attributes.url) {
      window.open(item.attributes.url, "_blank");
    }else if(item.attributes.calendar){
      this.router.navigate([`/event-calendar/${item.attributes.category.data.id}`]);
    }else if(item.attributes.file.data){
      window.open(item.attributes.file.data.attributes.url, "_blank");
    }
  }

}
