import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { DateTime } from "luxon"

@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.css']
})
export class EventCalendarComponent implements OnInit {

  constructor(private _Activatedroute: ActivatedRoute, private api: ApiService, private sanitizer: DomSanitizer, private snackBar: MatSnackBar) { }
  eventDates: any = [];
  date: Date[] | undefined;
  currentEvents: any = [];
  id: any

  async ngOnInit() {
    this._Activatedroute.params.subscribe(async params => {
      // Defaults to 0 if no query param provided.
      this.id = params['id'] || 0;

      var date = new Date();
      var firstDay = new Date().toISOString().slice(0, 8) + '01';
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().split('T')[0];

      this.eventDates = await this.getEvents(firstDay, lastDay)

      this.currentEvents = await this.getEvents(new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0])
    })
  }

  async getEvents(firstDay, lastDay) {

    let monthEvents: any = await this.api.getEvents2(this.id, 'el', firstDay, lastDay).toPromise()


    for (let index = 0; index < monthEvents.data.length; index++) {
      const element = monthEvents.data[index];
      element.day = element.attributes.event_date.slice(-2)
      element.year = element.attributes.event_date.slice(0, 4)
      element.month = element.attributes.event_date.slice(5, 7)

    }

    return monthEvents.data
  }


  checkDateForHoliday(date: any) {

    let isEvent = false
    for (let index = 0; index < this.eventDates.length; index++) {
      const element = this.eventDates[index];
      date.day = date.day.toString()
      if (date.day.length == 1) {
        date.day = `0${date.day}`
      }

      // console.log(element.day, `${date.day}`)
      if (element.day == `${date.day}`) {
        // console.log('trueeeeeeeee')
        isEvent = true
      }

    }

    return isEvent
  }

  async onSelect(datee) {


    var date = datee.toISOString()
    let formattedDate = DateTime.fromISO(date.toString());
    console.log(formattedDate.toString().slice(0, 10))
    let d = formattedDate.toString().slice(0, 10)

    let event = await this.getEvents(d, d)
    if (event.length > 0) {
      this.currentEvents = event
    }


  }

  async onMonthChange(date) {
    console.log('month change', date)


    let dt = new Date(`${date.year}-${date.month}-01`).toISOString()

    const lastDayJan = DateTime.fromISO(dt)
      .endOf('month')
      .toString().slice(0, 10)
    const firstDay = DateTime.fromISO(dt)
      .startOf('month')
      .toString().slice(0, 10)
    console.log(firstDay)
    console.log(lastDayJan)
    this.eventDates = await this.getEvents(firstDay, lastDayJan)
  }
}
