<div class="container" >
  <div class="row">
    <div class="col-md-2 p-0 m-0" style="background: #f3f3f3;">
      <app-sidebar></app-sidebar>
    </div>
    <div class="col-md-10 p-0 m-0" style="background: #f3f3f3;">
      <app-header></app-header>
      <router-outlet></router-outlet>
    </div>
    <div class="col-md-12 p-0 m-0" style="background: #f3f3f3">
      <app-footer></app-footer>
    </div>
  </div>
</div>