<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
    [fullScreen]="true"></ngx-spinner>
<div id="category">
    <div class="container mt-3">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngFor="let item of subs">
                <a *ngIf="item.children.length > 0" style="padding:0.5rem !important;color:#04375e"
                    routerLink="/menu-click-side/{{item.id}}">
                    <h4 style="font-size:1.2rem">{{item.attributes.name}}</h4>
                    <p style="font-weight:normal"> {{item.attributes.description}}</p>
                </a>

                <a *ngIf="item.attributes.page.data" style="padding:0.5rem !important;color:#04375e"
                    routerLink="/page/{{item.attributes.page.data.id}}">
                    <h4 style="font-size:1.2rem">{{item.attributes.name}}</h4>
                    <p style="font-weight:normal"> {{item.attributes.description}}</p>
                    <hr>
                </a>

                <a *ngIf="item.attributes.calendar" style="padding:0.5rem !important;color:#04375e"
                    routerLink="/event-calendar/{{item.attributes.category.data.id}}">
                    <h4 style="font-size:1.2rem">{{item.attributes.name}}</h4>
                    <p style="font-weight:normal"> {{item.attributes.description}}</p>
                    <hr>
                </a>

                <a *ngIf="item.attributes.category.data && !item.attributes.calendar" style="padding:0.5rem !important;color:#04375e"
                    routerLink="/category/{{item.attributes.category.data.id}}/1">
                    <h4 style="font-size:1.2rem">{{item.attributes.name}}</h4>
                    <p style="font-weight:normal"> {{item.attributes.description}}</p>
                    <hr>
                </a>

                <a *ngIf="item.attributes.url" style="padding:0.5rem !important;color:#04375e"
                    href="{{item.attributes.url}}">
                    <h4 style="font-size:1.2rem">{{item.attributes.name}}</h4>
                    <p style="font-weight:normal"> {{item.attributes.description}}</p>
                    <hr>
                </a>

                <a *ngIf="item.attributes.file.data" style="padding:0.5rem !important;color:#04375e"
                    href="{{item.attributes.file.data.attributes.url}}">
                    <h4 style="font-size:1.2rem">{{item.attributes.name}}</h4>
                    <p style="font-weight:normal"> {{item.attributes.description}}</p>
                    <hr>
                </a>
            </div>
        </div>
    </div>
</div>