<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
  [fullScreen]="true"
></ngx-spinner>
<div class="container-fluid">
  <div class="row cover" style="border-bottom: 3px solid #103652">
    <div class="frontbtns">
      <div class="frontbtns2">
        <a
          class="btn btn-primary"
          href="https://echamber.veth.gov.gr/"
          target="_blank"
          translate
          >become-member</a
        >
        <a
          class="btn btn-primary"
          style="margin-top: 0.5rem"
          routerLink="/page/24"
          translate
          >who-are-we</a
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-7 p-0 m-0">
      <ngb-carousel
        *ngIf="images"
        [interval]="3000"
        class="number-slide1"
        [animation]="true"
      >
        <ng-template ngbSlide *ngFor="let item of images">
          <a href="{{ item.attributes && item.attributes.url }}" target="_blank"
            ><img
              [src]="item.attributes.image.data.attributes.url"
              alt="item.attributes.alt_text"
              class="w-100 img-fluid"
              style="object-fit: cover; height: 21rem"
          /></a>

          <div class="carousel-caption">
            <a
              href="{{ item.attributes && item.attributes.url }}"
              target="_blank"
              style="color: black"
            >
              <p>{{ item.attributes.title }}</p>
            </a>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
    <div
      class="col-md-5 p-0 m-0"
      style="display: flex; flex-direction: column; justify-content: center"
    >
      <!-- <a style="text-decoration: none;" href="https://forms.office.com/e/hBJUuu4qim">
        <img class="img-fluid" src="../../assets/PROSKLISI-EKDILOSI.png" />
      </a> -->
    </div>
  </div>
  <div class="row" style="border-top: 3px solid #103652; padding-bottom: 1rem">
    <div class="col-lg-12 col-md-12 col-12 mt-4 p-0 m-0">
      <div class="card service-wrapper rounded border-0 shadow p-4">
        <div class="container-fluid">
          <div class="row" style="display: flex; color: #057cfe">
            <div
              class="col-md-2"
              style="
                display: flex;
                flex-direction: column;
                color: #057cfe;
                padding: 1rem;
              "
            >
              <img
                class="img-fluid"
                src="https://cms.veth.gov.gr/uploads/MAKARIOS_PAPADOPOYLOS_PROEDROS_BETH_082d0d87b4.jpg?updated_at=2024-12-24T08:12:09.566Z"
              />
            </div>
            <div
              class="col-md-10"
              style="
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                color: black;
                padding: 1rem;
                font-size: 0.9rem !important;
              "
            >
              <p>
                Το Βιοτεχνικό Επιμελητήριο Θεσσαλονίκης, φέτος συμπληρώνει τα
                100 χρόνια ζωής του. Το ΒΕΘ υπηρετώντας εδώ και έναν αιώνα την
                ανάπτυξη του τόπου, εκφράζει ένα ξεκάθαρο όραμα. Το όραμα ενός
                κλάδου της οικονομίας που αντλεί δύναμη από τη δημιουργικότητα,
                τις ικανότητες και τις φιλοδοξίες των ανθρώπων του, που παράγει
                εθνικό πλούτο, θέσεις εργασίας και συμβάλει στην κοινωνική
                συνοχή. Το όραμα μιας επιχειρηματικότητας που λειτουργεί με
                ευθύνη απέναντι στην κοινωνία, που δημιουργεί ευκαιρίες για
                πρόοδο και ευημερία.
              </p>
              <p>
                Στην ιστορική αυτή συγκυρία, των 100 χρόνων, το ΒΕΘ, γυρίζει
                σελίδα, γίνεται περισσότερο εξωστρεφές, προκειμένου να έρθει πιο
                κοντά στις επιχειρήσεις – μέλη του, που αποτελούν την δύναμη
                του. Στόχος μας να σταθούμε δίπλα στις μικρομεσαίες επιχειρήσεις
                με ουσιαστικότερο και πιο εποικοδομητικό τρόπο μέσα από σειρά
                δράσεων και ενεργειών.
              </p>
              <p>
                Σκοπός μας είναι, αρχής γενομένης από τη φετινή χρονιά, που το
                Επιμελητήριο γιορτάζει τα 100α του γενέθλια και παράλληλα έχει
                μια νέα διοίκηση – έτοιμη για δουλειά – να εδραιώσουμε ένα
                ισχυρό Επιμελητήριο, που θα εξελιχθεί στον καθημερινό σύμμαχο
                της επιχειρηματικότητας, παρέχοντας στήριξη, ενθάρρυνση και νέα
                εργαλεία, εξειδικευμένα κατά περίπτωση, προκειμένου οι
                βιοτεχνίες να ανταποκριθούν στις μεγάλες προκλήσεις της
                σύγχρονης οικονομίας.
              </p>
              <div class="president">Μακάριος Παπαδόπουλος , Πρόεδρος Βιοτεχνικού Επιμελητηρίου Θεσσαλονίκης </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-12 mt-4 p-0 m-0">
      <div class="card service-wrapper rounded border-0 shadow p-4">
        <h2 class="headerStyle" style="margin-top: 1rem" translate>
          most-popular
        </h2>
        <div *ngFor="let item of latestNews" style="font-size: 0.9rem">
          <a class="text-custom" routerLink="/page/{{ item.id }}">
            <img
              *ngIf="item.cover"
              class="img-fluid rounded"
              [src]="item.cover"
              style="width: 8rem"
            />
            <p>{{ item.attributes.title }}</p>
          </a>
          <hr />
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-md-6 col-12 p-0 m-0 mt-2 pt-2"
      style="padding-right: 0.5rem !important"
    >
      <div class="card service-wrapper rounded border-0 shadow p-4 card2">
        <div class="icon text-center text-custom h1 shadow rounded bg-white">
          <img
            class="img-fluid"
            src="/assets/desktop.png"
            alt="computer-icon"
          />
        </div>
        <div class="content mt-4">
          <h5 class="title" translate>e-chamber</h5>
          <p class="text-muted mt-3 mb-0" translate>e-chamberDesc</p>
          <div class="mt-3">
            <a
              class="text-custom"
              href="https://echamber.veth.gov.gr/"
              target="_blank"
              translate
              >more</a
            >
            <i class="mdi mdi-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-md-6 col-12 p-0 m-0 mt-2 pt-2"
      style="padding-right: 0.5rem !important"
    >
      <div class="card service-wrapper rounded border-0 shadow p-4 card2">
        <div class="icon text-center text-custom h1 shadow rounded bg-white">
          <img class="img-fluid" src="/assets/news2.png" alt="computer-icon" />
        </div>
        <div class="content mt-4">
          <h5 class="title" translate>news</h5>
          <div class="mt-1">
            <div
              *ngFor="let item of news"
              style="padding: 0.5rem; font-size: 0.9rem"
            >
              <a
                class="text-custom"
                routerLink="/page/{{ item.id }}"
                translate
                >{{ item.attributes.title.substring(0, 80) }}</a
              >
            </div>
          </div>
          <div class="mt-1">
            <a class="text-custom" routerLink="/menu-click-side/39" translate
              >more</a
            >
            <i class="mdi mdi-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-md-6 col-12 p-0 m-0 mt-2 pt-2"
      style="padding-right: 0.5rem !important"
    >
      <div class="card service-wrapper rounded border-0 shadow p-4 card2">
        <div class="icon text-center text-custom h1 shadow rounded bg-white">
          <img
            class="img-fluid"
            src="/assets/suitcase.png"
            alt="computer-icon"
          />
        </div>
        <div class="content mt-4">
          <h5 class="title" translate>selfinventory</h5>
          <p class="text-muted mt-3 mb-0" translate>selfinventoryDesc</p>
          <div class="mt-3">
            <a
              class="text-custom"
              href="https://services.businessportal.gr/"
              target="_blank"
              translate
              >more</a
            >
            <i class="mdi mdi-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-md-6 col-12 p-0 m-0 mt-2 pt-2"
      style="padding-right: 0.5rem !important"
    >
      <div class="card service-wrapper rounded border-0 shadow p-4 card2">
        <div class="icon text-center text-custom h1 shadow rounded bg-white">
          <img
            class="img-fluid"
            src="/assets/megaphone.png"
            alt="computer-icon"
          />
        </div>
        <div class="content mt-4">
          <h5 class="title" translate>announcements</h5>
          <div class="mt-1">
            <div
              *ngFor="let item of anouncements"
              style="padding: 0.5rem; font-size: 0.9rem"
            >
              <a
                class="text-custom"
                routerLink="/page/{{ item.id }}"
                translate
                >{{ item.attributes.title.substring(0, 80) }}</a
              >
            </div>
          </div>
          <div class="mt-1">
            <a class="text-custom" routerLink="/menu-click-side/34" translate
              >more</a
            >
            <i class="mdi mdi-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-md-6 col-12 p-0 m-0 mt-2 pt-2"
      style="padding-right: 0.5rem !important"
    >
      <div class="card service-wrapper rounded border-0 shadow p-4 card2">
        <div class="icon text-center text-custom h1 shadow rounded bg-white">
          <img class="img-fluid" src="/assets/node.png" alt="computer-icon" />
        </div>
        <div class="content mt-4">
          <h5 class="title" translate>gemi</h5>
          <p class="text-muted mt-3 mb-0" translate>gemiDesc</p>
          <div class="mt-3">
            <a
              class="text-custom"
              href="https://www.businessportal.gr/"
              target="_blank"
              translate
              >more</a
            >
            <i class="mdi mdi-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-md-6 col-12 p-0 m-0 mt-2 pt-2"
      style="padding-right: 0.5rem !important"
    >
      <div class="card service-wrapper rounded border-0 shadow p-4 card2">
        <div class="icon text-center text-custom h1 shadow rounded bg-white">
          <img class="img-fluid" src="/assets/press2.png" alt="computer-icon" />
        </div>
        <div class="content mt-4">
          <h5 class="title" translate>press-release</h5>
          <div class="mt-1">
            <div
              *ngFor="let item of press_release"
              style="padding: 0.5rem; font-size: 0.9rem"
            >
              <a
                class="text-custom"
                routerLink="/page/{{ item.id }}"
                translate
                >{{ item.attributes.title.substring(0, 80) }}</a
              >
            </div>
          </div>
          <div class="mt-1">
            <a class="text-custom" routerLink="/menu-click-side/38" translate
              >more</a
            >
            <i class="mdi mdi-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-md-6 col-12 p-0 m-0 mt-2 pt-2"
      style="padding-right: 0.5rem !important"
    >
      <div class="card service-wrapper rounded border-0 shadow p-4 card2">
        <div class="icon text-center text-custom h1 shadow rounded bg-white">
          <img
            class="img-fluid"
            src="/assets/services.png"
            alt="computer-icon"
          />
        </div>
        <div class="content mt-4">
          <h5 class="title" translate>one-step</h5>
          <p class="text-muted mt-3 mb-0" translate>one-step-desc</p>
          <div class="mt-3">
            <a
              class="text-custom"
              href="https://eyms.businessportal.gr/auth"
              target="_blank"
              translate
              >more</a
            >
            <i class="mdi mdi-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-md-6 col-12 p-0 m-0 mt-2 pt-2"
      style="padding-right: 0.5rem !important"
    >
      <div class="card service-wrapper rounded border-0 shadow p-4 card2">
        <div class="icon text-center text-custom h1 shadow rounded bg-white">
          <img
            class="img-fluid"
            src="/assets/opinions.png"
            alt="computer-icon"
          />
        </div>
        <div class="content mt-4">
          <h5 class="title" translate>views</h5>
          <div class="mt-1">
            <div
              *ngFor="let item of opinions_views"
              style="padding: 0.5rem; font-size: 0.9rem"
            >
              <a
                class="text-custom"
                routerLink="/page/{{ item.id }}"
                translate
                >{{ item.attributes.title.substring(0, 80) }}</a
              >
            </div>
          </div>
          <div class="mt-1">
            <a class="text-custom" routerLink="/menu-click-side/35" translate
              >more</a
            >
            <i class="mdi mdi-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-md-6 col-12 p-0 m-0 mt-2 pt-2"
      style="padding-right: 0.5rem !important"
    >
      <div
        style="margin-top: 1rem"
        class="card service-wrapper rounded border-0 shadow p-4"
      >
        <div
          class="col-md-12 p-3 m-0"
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <img
            class="img-fluid"
            src="/assets/opinions-removebg-preview.png"
            style="width: 4rem"
          />
          <h4 style="text-align: center; margin-top: 0.5rem">
            Μοιραστείτε μαζί μας τις απόψεις και προτάσεις σας
          </h4>
          <p>Κάντε κλικ <a routerLink="/opinion-form">εδώ</a></p>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-md-6 col-12 p-0 m-0 mt-2 pt-2"
      style="padding-right: 0.5rem !important"
    >
      <div
        style="margin-top: 1rem"
        class="card service-wrapper rounded border-0 shadow p-4"
      >
        <div
          class="col-md-12 p-3 m-0"
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <img
            class="img-fluid"
            src="/assets/job-search.png"
            style="width: 4rem"
          />
          <h4 style="text-align: center; margin-top: 0.5rem">
            Ψηφιακές Υπηρεσίες Αναζήτησης Εργαζομένων
          </h4>
          <p>
            Κάντε κλικ
            <a href="https://apasxolisi.veth.gov.gr/" target="_blank">εδώ</a>
          </p>
        </div>
      </div>
    </div>
    <h2 class="headerStyle" style="margin-top: 2rem" translate>apps</h2>
    <div class="row" style="margin-top: 1rem">
      <div class="col-lg-6 wrap-service13-box">
        <div class="card card-shadow border-0 mb-4">
          <div class="row no-gutters align-items-stretch">
            <div class="col-md-6 d-flex align-items-stretch">
              <img class="img-fluid" src="/assets/helpdesk.jpg" alt="wrapkit" />
            </div>
            <div class="col-md-6 p-3 d-flex align-items-stretch">
              <div class="ml-2">
                <h6 class="font-weight-medium mt-2">
                  <a
                    class="linking"
                    href="https://kb.veth.gov.gr"
                    target="_blank"
                    translate
                    >helpdesk</a
                  >
                  <p class="mt-3" translate>helpdesk_desc</p>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 wrap-service13-box">
        <div class="card card-shadow border-0 mb-4">
          <div class="row no-gutters align-items-stretch">
            <div class="col-md-6 d-flex align-items-stretch">
              <img class="img-fluid" src="/assets/seminars.jpg" alt="wrapkit" />
            </div>
            <div class="col-md-6 p-3 d-flex align-items-stretch">
              <div class="ml-2">
                <h6 class="font-weight-medium mt-2">
                  <a
                    class="linking"
                    href="https://seminars.veth.gov.gr"
                    target="_blank"
                    translate
                    >seminars</a
                  >
                  <p class="mt-3" translate>seminars_desc</p>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 wrap-service13-box">
        <div class="card card-shadow border-0 mb-4">
          <div class="row no-gutters align-items-stretch">
            <div class="col-md-6 d-flex align-items-stretch">
              <img
                class="img-fluid"
                src="/assets/marketplace.jpeg"
                alt="wrapkit"
              />
            </div>
            <div class="col-md-6 p-3 d-flex align-items-stretch">
              <div class="ml-2">
                <h6 class="font-weight-medium mt-2">
                  <a
                    class="linking"
                    href="https://marketplace.veth.gov.gr"
                    target="_blank"
                    >Marketplace</a
                  >
                  <p class="mt-3" translate>marketplace</p>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 wrap-service13-box">
        <div class="card card-shadow border-0 mb-4">
          <div class="row no-gutters align-items-stretch">
            <div class="col-md-6 d-flex align-items-stretch">
              <img
                class="img-fluid"
                src="/assets/catalogue.png"
                alt="wrapkit"
              />
            </div>
            <div class="col-md-6 p-3 d-flex align-items-stretch">
              <div class="ml-2">
                <h6 class="font-weight-medium mt-2">
                  <a
                    class="linking"
                    href="https://catalogue.veth.gov.gr"
                    target="_blank"
                    translate
                    >catalogue</a
                  >
                  <p class="mt-3" translate>catalogue_desc</p>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" translate>
      {{ popupData.attributes.title }}
    </h4>
    <button
      type="button"
      class="btn close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="margin-top: 3vh" [innerHTML]="body"></div>
  </div>
</ng-template>
