import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private router: Router
  ) {}
  navItems: any[] = [];
  menus: any[] = [];
  children: any[] = [];
  query: string;
  @ViewChild('template', { static: false }) public template: any;

  async ngOnInit() {
    await this.fetchMenus('el');

    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      if (event.lang == 'en') {
        await this.fetchMenus('en');
      }

      if (event.lang == 'el') {
        await this.fetchMenus('el');
      }
    });
  }

  //the function used to fetch the menu items. Is used at the ngOnInit, and at the onLangChange event.
  async fetchMenus(language: string) {
    this.navItems = [];
    let menus: any = await this.api.getMenus(language).toPromise();
    this.menus = menus.data;
    menus.data.forEach((element) => {
      if (!element.attributes.parent.data) {
        this.navItems = [...this.navItems, element];
      }
    });

    this.navItems = this.navItems.sort((a, b) =>
      a.attributes.rank > b.attributes.rank
        ? 1
        : b.attributes.rank > a.attributes.rank
        ? -1
        : 0
    );
    this.menus = this.navItems;
    this.menus.forEach((element: { id: number; children: any[] }) => {
      element.children = [];
      menus.data.forEach(
        (obj: { attributes: { parent: { data: { id: number } } } }) => {
          if (
            obj.attributes.parent.data &&
            obj.attributes.parent.data.id == element.id
          ) {
            element.children = [...element.children, obj];
          }
        }
      );
      element.children.sort((a, b) =>
        a.attributes.rank < b.attributes.rank
          ? 1
          : b.attributes.rank < a.attributes.rank
          ? -1
          : 0
      );
    });
  }

  clickMenuItem(item) {
    // console.log(item);
    // console.log(this.template.nativeElement);
    if (item.attributes.url) {
      window.open(item.attributes.url, '_blank');
    } else {
      this.template.nativeElement.style.display = 'block';

      for (let index = 0; index < this.menus.length; index++) {
        const element = this.menus[index];
        if (element.id == item.id) {
          this.children = element.children;
        }
      }
    }
  }

  close() {
    this.template.nativeElement.style.display = 'none';
  }

  async renderContent(item) {
    if (item.attributes.category.data) {
      this.router.navigate([`/category/${item.attributes.category.data.id}/1`]);
    } else if (item.attributes.page.data) {
      this.router.navigate([`/page/${item.attributes.page.data.id}`]);
    } else if (item.attributes.url) {
      window.open(item.attributes.url, '_blank');
    } else {
      this.router.navigate([`/menu-click-side/${item.id}`]);
    }
    this.close();
  }

  //the goal of this function is to find the ids of all pages that contain at least one word of the query, ie the text that user searched
  async search() {
    // console.log(keys)
    this.router.navigate(['/search'], {
      queryParams: { queryText: this.query },
    });
  }
}
