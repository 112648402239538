<div id="page">
    <div class="container mt-3">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="color:white;font-size:0.8rem">
                <article>
                    <header class="mb-4">
                        <h2 class="fw-bolder mb-1" style="font-size:1.5rem">{{article.attributes.title}}</h2>
                        <div class="text fst-italic mb-2" style="color:#04375e" translate>posted <span>{{article.attributes.published}}</span></div>
                    </header>
                    <figure *ngIf="article.attributes.cover.data">
                        <img class="img-fluid rounded" [src]="cover">
                    </figure>
                    <section class="mb-2" style="color:#04375e;font-size:0.8rem" [innerHTML]="body"></section>
                    <section class="mb-2" style="color:#04375e;" *ngIf="article.attributes.files.data">
                    <p>Downloads:</p>
                    <div *ngFor="let item of article.attributes.files.data">
                        <a class="link" style="color:#04375e" target="_blank" href='{{item.attributes.url}}'>{{item.attributes.name}}</a>
                    </div>
                    </section>

                    <section class="mb-2" style="color:#04375e;" *ngIf="article.attributes.photos.data">
                        <p>Photos:</p>
                        <div *ngFor="let item of article.attributes.photos.data">
                            <a class="link" style="color:#04375e" target="_blank" href='{{item.attributes.url}}'>
                            <img class="img-thumbnail" src="{{item.attributes.url}}" alt="{{item.attributes.name}}"></a>
                        </div>
                        </section>
                </article>
            </div>
        </div>
    </div>
</div>
