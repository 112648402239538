<div id="contact" class="container bootstrap snippets bootdeys" style="margin-top:2rem">
    <div class="row">
        <div class="col-md-12">
            <h4>Φόρμα Άποψης - Πρότασης</h4>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <form id="ajax-form form-main" role='form' name='ajax-form' #form="ngForm" style="padding: 1rem;">
                <div class="form-group">
                    <label for="name" translate>name-lastname<span style="color:red; margin-left:3px">*</span></label>
                    <input id="name" class="form-control" name="name" type="text" ngModel required>
                </div> 
                <div class="form-group">
                    <label for="phone" translate>phone<span style="color:red; margin-left:3px">*</span></label>
                    <input id="phone" class="form-control" name="phone" type="text" ngModel required>
                </div> 
                <div class="form-group">
                    <label for="email" translate>Email<span style="color:red; margin-left:3px">*</span></label>
                    <input id="email" class="form-control" name="email" type="text" ngModel required>
                </div> 
                <div class="form-group">
                    <label for="message" translate>message<span style="color:red; margin-left:3px">*</span></label>
                    <textarea id="message" class="form-control" name="message" rows='5' cols='30' style="height: 10rem;" ngModel></textarea>
                </div> 
                <div class="form-group" style="margin-top: 1rem;">
                    <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel"
                    [(ngModel)]="token" required
                    [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
                </div>
                <div class="form-group" style="margin-top: 1rem;">
                   <button id="send" class="btn btn-primary btn-shadow btn-rounded w-md" (click)="send(form)" translate>send</button>
                </div>
            </form>
        </div>
        <div class="col-md-3"></div>
    </div>
</div>