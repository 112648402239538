import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private api: ApiService, private translate: TranslateService,private router: Router) { }
  navItems: any[] = []
  flag = '/assets/uk-flag.gif'
  language = 'el'
  
  async ngOnInit() {
    if(this.translate.currentLang == 'en'){
      this.language = 'en'
    }else{
      this.language = 'el'
    }
    await this.fetchMenus(this.language);

    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      if (event.lang == 'en') {
        await this.fetchMenus('en');
      }
      
      if (event.lang == 'el') {
        await this.fetchMenus('el');
      }
    })
  }

  //the function used to fetch the menu items. Is used at the ngOnInit, and at the onLangChange event.
  async fetchMenus(language: string) {
    this.navItems = []
    let menus: any = await this.api.getHeaderLinks(language).toPromise();
    menus.data.forEach(element => {
      if (!element.attributes.parent.data) {
        this.navItems = [...this.navItems, element]
      }
    })

    this.navItems = this.navItems.sort((a, b) => (a.attributes.rank > b.attributes.rank) ? 1 : ((b.attributes.rank > a.attributes.rank) ? -1 : 0))

  }

  // Method to change the language.
  async switchLanguage() {
    console.log('ell')
    if (this.translate.currentLang == 'en') {
      this.flag = '/assets/uk-flag.gif'
      this.translate.use('el');

    } else  {
      this.flag = '/assets/gr-flag.gif'
      this.translate.use('en');


    }
   


  }

  async renderContent(item) {
    console.log(item)
    if (item.attributes.category.data) {
      this.router.navigate([`/category/${item.attributes.category.data.id}/1`]);
    } else if (item.attributes.page.data) {
      this.router.navigate([`/page/${item.attributes.category.data.id}`]);
    } else if (item.attributes.url) {
      this.router.navigate([`${item.attributes.url}`]);
    } else {
      this.router.navigate([`/menu-click/${item.id}`]);
    }
  }
}
