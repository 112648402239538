import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  constructor(private api: ApiService, private translate: TranslateService,private spinner: NgxSpinnerService, private sanitizer: DomSanitizer,
    private modalService: NgbModal,private cdr: ChangeDetectorRef,) { }
  images: any[] | undefined;
  apiurl = environment.apiUrlPublic
  latestNews: any[] = []
  news: any[] = []
  anouncements: any[] = []
  press_release: any[] = []
  opinions_views: any[] = []
  popupData: any
  body: any
  videoSource = '/assets/video-4.mp4'
  @ViewChild('content', { static: true }) public content: ElementRef;
  // @ViewChild('videoPlayer')  videoplayer!: ElementRef;

  async ngOnInit(): Promise<void> {
    let language: string = this.translate.currentLang === undefined ? "el" : this.translate.currentLang;
    this.spinner.show()

    // this.cdr.detectChanges();
    // this.videoplayer.nativeElement.play();

    let popup: any = await this.api.gePopup(language).toPromise();
    this.popupData = popup.data
    console.log(this.popupData)
    this.body = this.sanitizer.bypassSecurityTrustHtml(this.popupData.attributes.content)
    if (this.popupData.attributes.title !== null) {
      this.modalService.open(this.content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    }

    let images: any = await this.api.getSliders().toPromise();

    images.data.forEach((element: { attributes: { image: { data: { attributes: { url: string; }; }; }; }; }) => {
      element.attributes.image.data.attributes.url = `${this.apiurl}${element.attributes.image.data.attributes.url}`
    });
   

    this.images = images.data
    this.images.sort((a, b) =>
      a.attributes.rank > b.attributes.rank
        ? 1
        : b.attributes.rank > a.attributes.rank
        ? -1
        : 0
    );

    let links: any = await this.api.getLinks(language).toPromise()

    this.latestNews = await this.fetchElements(links.data.attributes.latest_news, language, '3')
    this.news = await this.fetchElements(links.data.attributes.news, language, '3')
    this.anouncements = await this.fetchElements(links.data.attributes.anouncements, language, '3')
    this.press_release = await this.fetchElements(links.data.attributes.press_release, language, '3')
    this.opinions_views = await this.fetchElements(links.data.attributes.opinions_views, language, '3')
    this.spinner.hide()

    //react to the language changing event
    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      this.spinner.show()
      links = await this.api.getLinks(event.lang).toPromise()

      this.latestNews = await this.fetchElements(links.data.attributes.latest_news, event.lang, '3')
      this.news = await this.fetchElements(links.data.attributes.news, event.lang, '3')
      this.anouncements = await this.fetchElements(links.data.attributes.anouncements, event.lang, '3')
      this.press_release  = await this.fetchElements(links.data.attributes.press_release, event.lang, '3')
      this.opinions_views = await this.fetchElements(links.data.attributes.opinions_views, event.lang, '3')
      this.spinner.hide()
    })
  }

  // ngAfterViewInit() {
  //   this.spinner.show();
  //   this.cdr.detectChanges();
  //   this.videoplayer.nativeElement.play();
  //   this.spinner.hide();
  // }

//   toggleVideo() {
//     this.videoplayer.nativeElement.play();
// }


  //the function used to fetch elements from the strapi. Ie fetch pages of type: 'epikairothta', 'diagonismoi'
  async fetchElements(id: number, locale, limit): Promise<any> {
    let b: any = await this.api.getCategory2(id, limit, locale, 1).toPromise()

    let box = b.data

    box.forEach((element: any) => {
      var date = new Date(element.attributes.published);
      var n = date.toLocaleDateString();
      element.attributes.published = n

      if (element.attributes.cover.data) {
        element.cover = `${this.apiurl}${element.attributes.cover.data.attributes.url}`
      } else {
        // let x = this.randomIntFromInterval(1, 3)
        element.cover = `/assets/veth_logo.png`

      }


    });

    return box;
  }

}
