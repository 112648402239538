import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-useful-links',
  templateUrl: './useful-links.component.html',
  styleUrls: ['./useful-links.component.css']
})
export class UsefulLinksComponent implements OnInit {

  constructor(private api: ApiService, private translate: TranslateService) { }
  links: any[] = []

  async ngOnInit(): Promise<void> {
    let links: any = await this.api.getUsefulLinks('el').toPromise()
    this.links = links.data

    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      links = await this.api.getUsefulLinks(event.lang).toPromise()
      this.links = links.data
    })
  }

}
