import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.css']
})
export class PollsComponent implements OnInit {

  constructor(private api: ApiService, private translate: TranslateService) { }
  polls: any[] = []

  async ngOnInit(): Promise<void> {
    let polls: any = await this.api.getPolls('el').toPromise()
    this.polls = polls.data

    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      polls = await this.api.getPolls(event.lang).toPromise()
      this.polls = polls.data
    })
  }

}
